import React from "react";
import { useTranslation } from "react-i18next";

const CatalogItemPreview = (props) => {
  const { t } = useTranslation();

  return (
    <div className='cool-catalog-item'>
      <div className='cool-catalog-container'>
        <div className='catalog-item-img'>
          <img src={require(`../img/${props.img}`)} alt='' />
        </div>
        <div className='catalog-item-text'>{t(props.title)}</div>
      </div>
    </div>
  );
};

export default CatalogItemPreview;
