import React, { Fragment } from "react";
import arrow from "../img/arrow.svg";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useTranslation } from "react-i18next";

const CatalogItem = (props) => {
  const { t } = useTranslation();
  const popUpHandle = () => {
    $(".blur").addClass("active");
    $(".pop-up-form-container").fadeIn(100);
    props.setData(props.title);
  };

  return (
    <div className='catalog-item-component'>
      <div className='catalog-item-title'>
        <Link to='/stp'>
          <div className='catalog-back-trigger'>
            <img src={arrow} alt='' />
          </div>
        </Link>
        {t(props.title)}
        <div onClick={() => popUpHandle()} className='btn-catalog-item-form'>
          {t("stp.ButtonRequest")}
        </div>
      </div>
      <div className='catalog-item-main-img'>
        {props.images &&
          props.images.length > 0 &&
          props.images.map((img) => {
            return <img key={Math.random()} className='catalog-page-image' src={require(`../img/${img}`)} alt='' />;
          })}
      </div>
      <div className='catalog-item-main-text'>
        <div className='item-text-row'>
          <div className='title-two'>{t("stp.CatalogItem.Title-description")}</div>
          {t(props.description)}
        </div>
        <div onClick={() => popUpHandle()} className='btn-catalog-item-form-mobile'>
          {t("stp.ButtonRequest")}
        </div>
      </div>
    </div>
  );
};

export default CatalogItem;
