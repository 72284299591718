import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CatalogItemPreview from "./catalog-item-preview";
import catalog from "../catalog.json";

const Catalog = () => {
  const { t } = useTranslation();

  return (
    <div className='catalog-component'>
      <div className='catalog-catalog'>
        <div className='catalog-title'></div>
        {Object.keys(catalog).map((key) => {
          return (
            <Link key={Math.random()} to={"/stp" + key}>
              <CatalogItemPreview img={catalog[key].images[0]} title={catalog[key].title} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Catalog;
