import React, { useState } from "react";
import arrow from "./img/arrow.svg";
import { useTranslation } from "react-i18next";
import { Route, Switch, Link } from "react-router-dom";
import Catalog from "./pages/catalog.js";
import CatalogItem from "./pages/catalog-item.js";
import catalog from "./catalog.json";

const Stp = () => {
  const [state, setState] = useState({
    service_name: "",
    loaded: false,
  });

  const setDataFromProps = (service_name) => {
    setState({ ...state, service_name: service_name });
  };

  const { t } = useTranslation();

  const firstscreenRender = () => {
    let fsName = "firstscreen-common fs-common-3";
    return (
      <div className={fsName}>
        <div className='mask'>
          <div className='text-container'>
            <Switch>
              <Route exact path={`/stp`}>
                <div className='cool-fs-title'>
                  <Link to={`/`}>
                    <div className='catalog-back-trigger common-back'>
                      <img src={arrow} alt='' />
                    </div>
                  </Link>
                  {t(`Menu.10`)}
                </div>
              </Route>
            </Switch>
          </div>
        </div>
        <div className='fader-common'></div>
      </div>
    );
  };

  console.log("catalog", catalog);

  return (
    <div className='repair'>
      {firstscreenRender()}
      <div className='main-wrapper'>
        <Switch>
          <Route exact path='/stp' component={Catalog} />

          {Object.keys(catalog).map((key) => {
            return (
              <Route key={Math.random()} path={"/stp" + key}>
                <CatalogItem setData={setDataFromProps} {...catalog[key]} />
              </Route>
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default Stp;
