import React from 'react';


const Exhaust = () => {
  return (
<>
  <div style={{width: '100%', height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>Loading Tor...</div>
  <iframe style={{width: '100%', height: "100vh", position:'absolute', top: '0px', left: '0px', zIndex: '95', paddingTop: '60px'}} src="https://thor-tuning.ru/" scrolling={true} frameborder="0"/>
</>
  );
};

export default Exhaust;
